import React from 'react';

import { Grid } from '@material-ui/core';
import { AllSolutionsCard } from '../AllSolutions/AllSolutionsCard';
import { CtaCard } from '../General/CtaCard';

export const AllSolutionsBody = ({ solutions, cta }) => {
  const { ctaHeader, ctaSubheader, ctaBackground, ctaText } = cta;
  return (
    <>
      <Grid
        container
        direction='row'
        spacing={2}
        // className={classes.cards}
        justifyContent='center'
        style={{ paddingBottom: '4rem' }}
      >
        {solutions.map((solution, index) => (
          <AllSolutionsCard key={index} solution={solution} />
        ))}
      </Grid>
      <CtaCard
        background={ctaBackground}
        header={ctaHeader}
        subheader={ctaSubheader}
        ctaText={ctaText}
      />
    </>
  );
};
