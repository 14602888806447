import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';

import { Grid, Typography, Card, CardContent, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.workwaveBlue,
    height: '275px',
    padding: '0px',
    borderRadius: '20px',
    boxShadow: '0 10px 40px 0px rgba(0,0,0,0.15)',
    position: 'relative',
    paddinBottom: '0px !important',
    [theme.breakpoints.down('md')]: {
      height: '315px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '275px',
    },
    transition: 'transform 0.25s',
  },
  title: {
    fontSize: '1.4rem',
    fontWeight: 700,
    textAlign: 'center',
    margin: '1rem 0 0.5rem 0',
    color: '#002D5C',
  },
  pos: {
    textAlign: 'center',
    color: '#4B5B69',
  },

  button: {
    opacity: 1,
    background: '#2A7ABC',
    transition: 'all 0.35s ease !important',
    '&:hover': {
      background: '#2A7ABC',
      opacity: '0.85 !important',
    },
  },
  card: {},
}));

export const AllSolutionsCard = ({ solution }) => {
  const classes = useStyles();

  const {
    allSolutionsTitle,
    allSolutionsSynopsis,
    allSolutionsCta,
    faIcon,
    slug,
  } = solution;
  return (
    <Grid item xs={12} sm={6}>
      <Card className={classes.root} elevation={0}>
        <CardContent
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingBottom: '16px',
          }}
        >
          <Grid item xs={12} lg={8}>
            <Grid container item direction='row' justifyContent='center'>
              <FontAwesomeIcon
                icon={['fad', faIcon?.name ?? 'fish']}
                style={{
                  height: '55px',
                  width: '55px',
                  background: '#002D5C',
                  margin: '0 0 15px 0',
                  padding: '10px',
                  borderRadius: '100%',
                  '--fa-primary-color': faIcon?.colors[0]?.color ?? '#AA23FF',
                  '--fa-secondary-color': '#FFF',
                  '--fa-primary-opacity': 1,
                  '--fa-secondary-opacity': 1,
                }}
              />
            </Grid>
            {/* This is a band aid for pre localization. will need to change to a new field in sanity eventually. */}
            <Typography variant='body1' className={classes.title}>
              {allSolutionsTitle}
            </Typography>
            <Typography variant='body1' style={{ textAlign: 'center' }}>
              {allSolutionsSynopsis}
            </Typography>
          </Grid>

          <Link to={`/${slug.current}`}>
            <Button
              variant='contained'
              color='secondary'
              className={classes.button}
            >
              {allSolutionsCta}
            </Button>
          </Link>
        </CardContent>
      </Card>
    </Grid>
  );
};
